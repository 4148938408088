export const sideEffectsSignup = () => {
  window.dataLayer.push({
    event: 'event',
    eventCategory: 'Customer Signup',
    eventAction: 'Signup - Mobile No Typed',
    eventLabel: '/signup'
  });
};

export const sideEffectsLogin = () => {
  window.dataLayer.push({
    event: 'event',
    eventCategory: 'Customer Login',
    eventAction: 'Login - Mobile No Typed',
    eventLabel: '/login'
  });
};
