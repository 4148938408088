/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import Image from 'next/image';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import OtpInput from 'react-otp-input';
import { useRouter } from 'next/router';
import { useAuthDispatch, useAuthState } from 'components/auth/hook';
import { verifyOtpValidationSchema } from 'components/signUp/validations';
import Switch from 'components/atoms/switch';
import InputWithLabel from 'components/molecules/input-with-label';
import { CUSTOMER_LOGIN, PARTNER_LOGIN, PARTNER_SIGNUP_VERIFY, VALIDATION_MESSAGE, VERIFY_OTP } from 'src/formMapper';

const VerifyOtp = ({ phone, resendSuccess }) => {
  const { client } = useAuthState();
  const dispatch = useAuthDispatch();
  const router = useRouter();
  const [resendLoading, setResendLoading] = useState(false);

  const {
    handleSubmit,
    control,
    register,
    setError,
    setValue,
    watch,
    formState: { isSubmitting, errors }
  } = useForm({
    defaultValues: { callMe: true },
    resolver: yupResolver(verifyOtpValidationSchema)
  });

  const callMe = watch('callMe');

  const onFormSubmit = async (values) => {
    try {
      const myuuid = uuidv4();
      const response = await client.post('/auth/token/', {
        contact_number: phone,
        otp: values.otp,
        callMe: values.callMe
      });
      const {
        data: { access, refresh }
      } = response;
      window.localStorage.setItem('access', access);
      window.localStorage.setItem('refresh', refresh);
      const account = await client.get('/account/');
      dispatch({
        type: 'set',
        payload: {
          user: account.data.data.user,
          completed_screens: account.data.data.completed_screens
        }
      });

      if (router.pathname === '/signup') {
        window.dataLayer.push({ event: 'sign_up' });
      }

      window.FS.identify(myuuid, {
        firstName: account.data.data.user.first_name,
        email: account.data.data.user.email,
        mobileNumber: account.data.data.user.contact_number
      });

      router.push('/loan-requirement');
    } catch (error) {
      setError('otp', {
        type: 'server',
        message: VALIDATION_MESSAGE.invalidOtp
      });
    }
  };
  const resendOtp = async (values) => {
    try {
      setResendLoading(true);
      await client.post('/auth/mobile/retry/', { contact_number: phone });
      resendSuccess(values);
      setResendLoading(false);
    } catch (error) {
      setResendLoading(false);
      if (error.response?.status === 409) {
        setError('phone', {
          type: 'server',
          message: VALIDATION_MESSAGE.alreadyRegistered
        });
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <div className='space-y-6'>
        <InputWithLabel
          label={CUSTOMER_LOGIN.otp.label}
          name='otp'
          error={!!errors.otp}
          errorMessage={errors?.otp?.message}
          labelSize='large'
        >
          <Controller
            defaultValue=''
            control={control}
            name='otp'
            data-testid='otp'
            render={({ field: { onChange, ref, ...rest } }) => (
              <OtpInput
                containerStyle='space-x-3'
                inputStyle={{ width: '100%', fontSize: '1.5rem', lineHeight: '2rem' }}
                type='tel'
                numInputs={6}
                isInputNum
                onChange={(otp) => {
                  onChange(otp);
                }}
                inputRef={ref}
                {...rest}
                hasErrored={errors.otp}
                data-testid='otp'
              />
            )}
          />
        </InputWithLabel>
        <input type='hidden' {...register('otp')} data-testid='hidden-otp' />
        <div className='col-span-2'>
          <div className='flex items-center'>
            <label className='text-gray-400 app-text-dm-xs'>{VERIFY_OTP.label.didnotReceiveOtp}</label>
            <button
              type='button'
              className='ml-2 font-medium app-text-link text-primary'
              onClick={resendOtp}
              disabled={resendLoading}
              data-testid='submit'
              {...(router.pathname === '/signup'
                ? {
                    'data-category': 'signup_resend_otp',
                    'data-action': 'Signup Resend OTP',
                    'data-label': '-'
                  }
                : {
                    'data-category': 'login_resend_otp',
                    'data-action': 'Resend OTP',
                    'data-label': '-'
                  })}
            >
              {resendLoading && (
                <>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    className='w-6 h-6 animate-ping'
                    fill='none'
                    viewBox='0 0 24 24'
                    aria-hidden={!resendLoading}
                    stroke='currentColor'
                    data-testid='recendProgress'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth='2'
                      d='M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z'
                    />
                  </svg>
                </>
              )}{' '}
              {resendLoading ? '' : VERIFY_OTP.button.submit}
            </button>
          </div>
        </div>
        {router.pathname !== '/login' && (
          <div className='col-span-2'>
            <label htmlFor='callMe' className='inline-flex items-center'>
              <div className='relative flex-shrink-0 w-8 h-8 mr-4'>
                <Image src='/images/call.png' alt='Call me' objectFit='cover' width={32} height={32} />
              </div>
              <Switch onChange={(value) => setValue('callMe', value, { shouldValidate: true })} enabled={callMe} />
              <input className='sr-only' hidden aria-hidden='true' {...register('callMe')} />
              <span className='ml-2 text-sm opacity-80 whitespace-nowrap'>
                {VERIFY_OTP.help_text.formFillingAssistance}
              </span>
            </label>
          </div>
        )}
      </div>
      <div className='my-7'>
        <button
          className='app-btn app-btn-primary'
          type='submit'
          data-testid='submitOtp'
          {...(router.pathname === '/signup'
            ? {
                'data-category': 'Customer Signup',
                'data-action': 'Signup - Click on OTP Verification/Sign up Complete',
                'data-label': '/signup'
              }
            : {
                'data-category': 'Customer Login',
                'data-action': 'Login - Click on OTP Verification/Sign up Complete',
                'data-label': '/login'
              })}
        >
          {isSubmitting && (
            <svg
              xmlns='http://www.w3.org/2000/svg'
              className='w-6 h-6 animate-ping'
              fill='none'
              viewBox='0 0 24 24'
              aria-hidden={!isSubmitting}
              stroke='currentColor'
              data-testid='submitOtpProgress'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='2'
                d='M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z'
              />
            </svg>
          )}
          {!isSubmitting && (
            <svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' className='w-3 sm:block'>
              <g fill='none' fillRule='evenodd'>
                <g fill='currentColor' fillRule='nonzero'>
                  <path
                    d='M25 16c-4.97 0-9 4.03-9 9s4.03 9 9 9 9-4.03 9-9c0-2.387-.948-4.676-2.636-6.364C29.676 16.948 27.387 16 25 16zm0 2.7c1.491 0 2.7 1.209 2.7 2.7s-1.209 2.7-2.7 2.7-2.7-1.209-2.7-2.7 1.209-2.7 2.7-2.7zm5.237 10.05c-1.23 1.604-3.174 2.55-5.238 2.55-2.065 0-4.008-.946-5.239-2.55-.187-.267-.212-.611-.065-.902l.196-.397c.46-.946 1.445-1.55 2.526-1.551h5.164c1.066.001 2.038.59 2.507 1.515l.214.424c.15.293.126.641-.065.911z'
                    transform='translate(-16 -16)'
                  />
                </g>
              </g>
            </svg>
          )}
          <span>
            {router.pathname === '/login' ? PARTNER_LOGIN.button.submit : PARTNER_SIGNUP_VERIFY.button.submit}
          </span>
        </button>
        <input
          type='text'
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          {...register('otp1')}
          onChange={(e) => setValue('otp', e.target.value)}
          className='opacity-0'
        />
      </div>
    </form>
  );
};

VerifyOtp.propTypes = {
  phone: PropTypes.string.isRequired,
  resendSuccess: PropTypes.func.isRequired
};

export default VerifyOtp;
