import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ATOMS } from 'src/formMapper';

const Switch = ({ onChange, enabled }) => {
  return (
    <button
      type='button'
      onClick={() => onChange(!enabled)}
      className={classNames(
        enabled ? 'bg-secondary' : 'bg-transparent',
        'relative inline-flex flex-shrink-0 h-6 w-11 border border-secondary rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0'
      )}
    >
      <span className='sr-only'>{ATOMS.button.button_switch}</span>
      <span
        className={`
          ${
            enabled ? 'translate-x-[22px] bg-white' : 'translate-x-[3px] bg-secondary'
          } mt-[2px] pointer-events-none relative inline-block h-[18px] w-[18px] rounded-full bg-white shadow-switch-shadow  transform ring-0 transition ease-in-out duration-200`}
      >
        <span
          className={`
            ${
              enabled ? 'opacity-0 ease-out duration-100' : 'opacity-100 ease-in duration-200'
            } absolute inset-0 h-full w-full flex items-center justify-center transition-opacity`}
          aria-hidden={enabled}
        />
        <span
          className={`${
            enabled ? 'opacity-100 ease-in duration-200' : 'opacity-0 ease-out duration-100'
          } absolute inset-0 h-full w-full flex items-center justify-center transition-opacity`}
          aria-hidden={enabled}
        />
      </span>
    </button>
  );
};

Switch.propTypes = {
  onChange: PropTypes.func.isRequired,
  enabled: PropTypes.bool.isRequired
};

Switch.defaultProps = {};

export default Switch;
