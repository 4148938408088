import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { onlyNumbers } from 'src/helper';
import { useAuthState } from 'components/auth/hook';
import { sendOtpValidationSchema } from 'components/signUp/validations';
import { Input } from 'components/molecules/v2/input';
import InputWithLabel from 'components/molecules/input-with-label';
import { CUSTOMER_LOGIN, VALIDATION_MESSAGE } from 'src/formMapper';
import { useRouter } from 'next/router';
import { sideEffectsSignup, sideEffectsLogin } from 'components/signUp/helpers';

const SendOtp = ({ onSuccess, apiUrl, redirectUrl, redirectText, contactNo }) => {
  const { client } = useAuthState();
  const [isSend, setIsSend] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const router = useRouter();
  const {
    handleSubmit,
    control,
    formState: { isSubmitting, errors },
    setError
  } = useForm({
    defaultValues: {},
    resolver: yupResolver(sendOtpValidationSchema)
  });

  const onFormSubmit = async (values) => {
    window.localStorage.setItem('contactNo', values.phone);
    const utmParams = localStorage.getItem('utmParams') ? JSON.parse(localStorage.getItem('utmParams')) : {};
    try {
      await client.post(apiUrl, {
        contact_number: values.phone,
        utm_source: utmParams?.utm_source,
        utm_medium: utmParams?.utm_medium,
        utm_campaign: utmParams?.utm_campaign,
        utm_rm: utmParams?.utm_rm
      });

      setIsSend(true);
      onSuccess(values);
    } catch (error) {
      if (error.response.status === 409) {
        setError('phone', {
          type: 'server',
          message: VALIDATION_MESSAGE.numberRegistered
        });
        setDisabled(true);
      }
      if (error.response.status === 404) {
        setError('phone', {
          type: 'server',
          message: VALIDATION_MESSAGE.numberNotRegistered
        });
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onFormSubmit)} autoComplete='off'>
      <div className=''>
        <InputWithLabel
          label={CUSTOMER_LOGIN.phone.label}
          name='phone'
          error={!!errors.phone}
          errorMessage={errors?.phone?.message}
          labelSize='large'
        >
          <Controller
            defaultValue={contactNo || ''}
            control={control}
            name='phone'
            render={({ field: { onChange, ...rest } }) => (
              <Input
                name='phone'
                type='text'
                placeholder={CUSTOMER_LOGIN.phone.placeholder}
                error={!!errors.phone}
                errorMessage={errors?.phone?.message}
                onChange={(e) => {
                  const {
                    target: { value }
                  } = e;
                  onChange(onlyNumbers(value).substring(0, 10));
                  setDisabled(false);
                  if (router.pathname === '/signup') {
                    sideEffectsSignup();
                  }
                  if (router.pathname === '/login') {
                    sideEffectsLogin();
                  }
                }}
                {...rest}
              />
            )}
          />
          {errors.phone && errors.phone.type !== 'required' && errors.phone.type !== 'min' && (
            <div className='mt-6 text-xs sm:app-text-dm-small text-gray'>
              {errors.phone.message.includes('Number already registered') && 'Number already registered with Easiloan,'}
              {errors.phone.message.includes('Number not registered') && 'Number not registered with Easiloan,'}
              {(errors.phone.message.includes('Number already registered') ||
                errors.phone.message.includes('Number not registered')) && (
                <a href={redirectUrl} className='ml-2 text-xs text-secondary sm:text-sm app-text-link'>
                  {redirectText}
                </a>
              )}
              {!errors.phone.message.includes('Number already registered') &&
                !errors.phone.message.includes('Number not registered') && <>{errors.phone.message}</>}
            </div>
          )}
        </InputWithLabel>
        {!isSend && (
          <div className='my-6'>
            <button
              className='app-btn app-btn-primary md:min-w-[230px]'
              type='submit'
              data-testid='submit'
              disabled={disabled}
              {...(router.pathname === '/signup'
                ? {
                    'data-category': 'signup_request_otp',
                    'data-action': 'Request OTP',
                    'data-label': '-'
                  }
                : {
                    'data-category': 'login_request_otp',
                    'data-action': 'Request OTP',
                    'data-label': '-'
                  })}
            >
              {isSubmitting && (
                <>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    className='w-6 h-6 animate-ping'
                    fill='none'
                    viewBox='0 0 24 24'
                    aria-hidden={!isSubmitting}
                    stroke='currentColor'
                    data-testid='submitProgress'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth='2'
                      d='M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z'
                    />
                  </svg>
                </>
              )}{' '}
              {isSubmitting ? '' : CUSTOMER_LOGIN.otp.requestOtp}
            </button>
          </div>
        )}
      </div>
    </form>
  );
};

SendOtp.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  apiUrl: PropTypes.string.isRequired,
  redirectUrl: PropTypes.string.isRequired,
  redirectText: PropTypes.string.isRequired,
  contactNo: PropTypes.string.isRequired
};

export default SendOtp;
