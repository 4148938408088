import * as yup from 'yup';
import { validatePhone } from 'src/validations';
import { VALIDATION_MESSAGE } from 'src/formMapper';

export const sendOtpValidationSchema = yup
  .object()
  .shape({
    phone: validatePhone
  })
  .defined();

export const verifyOtpValidationSchema = yup
  .object()
  .shape({
    otp: yup.string().required(VALIDATION_MESSAGE.enterOtp).min(6)
  })
  .defined();
