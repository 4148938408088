import * as yup from 'yup';
import { transformNullToString } from 'src/helper';
import { VALIDATION_MESSAGE } from './formMapper';

// TODO: @madhav.milan make all value schema and object schema nullable

export const validatePincode = yup
  .string()
  .required(VALIDATION_MESSAGE.pinCode)
  .min(6, VALIDATION_MESSAGE.validPinCode)
  .max(6, VALIDATION_MESSAGE.validPinCode)
  .matches('^[1-9][0-9]{5}$', VALIDATION_MESSAGE.validPinCode)
  .nullable();

export const validateAddress = yup.string().required(VALIDATION_MESSAGE.current_address1).nullable();
export const validatePermanentAddress = yup.string().required(VALIDATION_MESSAGE.permanent_address).nullable();

export const validateFirstName = yup
  .string()
  .required(VALIDATION_MESSAGE.firstName)
  .max(100, VALIDATION_MESSAGE.validFirstName)
  .matches("^[ A-Za-z.,'-]*$", VALIDATION_MESSAGE.firstNameValidation)
  .nullable();

export const validateMiddleName = yup
  .string()
  .matches("^[ A-Za-z.,'-]*$", VALIDATION_MESSAGE.middleNameValidation)
  .nullable();

export const validateLastName = yup
  .string()
  .required(VALIDATION_MESSAGE.lastName)
  .max(100, VALIDATION_MESSAGE.validLastName)
  .matches("^[ A-Za-z.,'-]*$", VALIDATION_MESSAGE.lastNameValidation)
  .nullable();

export const validateName = yup
  .string()
  .trim()
  .required(VALIDATION_MESSAGE.name)
  .max(100, VALIDATION_MESSAGE.validName)
  .matches("^[ A-Za-z.,'-]*$", VALIDATION_MESSAGE.nameValidation)
  .nullable();

export const validateEmail = yup
  .string()
  .required(VALIDATION_MESSAGE.mailId)
  .typeError(VALIDATION_MESSAGE.mailId)
  .email(VALIDATION_MESSAGE.validmailId)
  .nullable();

export const validatePhone = yup
  .string()
  .required(VALIDATION_MESSAGE.mobileNum)
  .min(10, VALIDATION_MESSAGE.validmobileNum)
  .max(10, VALIDATION_MESSAGE.validmobileNum)
  .matches('^[5-9][0-9]{9}$', VALIDATION_MESSAGE.validmobileNum)
  .nullable();

export const validateAdhar = yup
  .string()
  .required(VALIDATION_MESSAGE.aadharNo)
  .typeError(VALIDATION_MESSAGE.aadharNo)
  .matches('^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$', VALIDATION_MESSAGE.validaadharNo)
  .nullable();

export const validatePancard = yup
  .string()
  .required(VALIDATION_MESSAGE.pancard)
  .typeError(VALIDATION_MESSAGE.pancard)
  .min(10, VALIDATION_MESSAGE.validPancard)
  .max(10, VALIDATION_MESSAGE.validPancard)
  .matches('^([A-Z]){5}([0-9]){4}([A-Z]){1}?$', VALIDATION_MESSAGE.validPancard)
  .nullable();

export const validateDateOfBirth = yup.string().required(VALIDATION_MESSAGE.DoB).nullable();

export const validateGender = yup.string().required(VALIDATION_MESSAGE.gender).nullable();

export const validateCity = yup.string().required(VALIDATION_MESSAGE.city).nullable();

export const validateState = yup.string().required(VALIDATION_MESSAGE.state).nullable();

export const validateMoney = yup
  .number()
  .required(VALIDATION_MESSAGE.monthlyIncome)
  .transform((value, originalValue) => {
    const val = transformNullToString(originalValue);
    return val === '' ? undefined : Number(val.replace(/, /g, ''));
  })
  .nullable();
